

import NavCardList from '../../../Partials/Layouts/NavCardList/NavCardList'
import NavCard from '../../../Partials/Layouts/NavCardList/NavCard/NavCard'
import { RiGroupLine } from 'react-icons/ri'
import { FaCalendarAlt } from 'react-icons/fa';


function DraftDashboard() {

    return (
        <NavCardList numOfCards={'four'}>
            <NavCard cardName={'Draft Rounds'} navCardLink='/draftRounds' ><RiGroupLine /></NavCard>
            <NavCard cardName={'Draft Users'} navCardLink='/draftAllUsers' ><FaCalendarAlt /></NavCard>
        </NavCardList>
    )
}

export default DraftDashboard