
function DashboardCard({count,title}) {
  return (
    <div className="card">
    <p>{title}:</p>
    <h3>{count}</h3>
</div> 
  )
}

export default DashboardCard
