import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Form from '../../../Partials/Layouts/Forms/Form';
import ShortTextInput from '../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput';
import FormSubmitButton from '../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton';
import PasswordInput from '../../../Partials/Layouts/Forms/FormInputs/PasswordInput/PasswordInput';
import ImageInput from '../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput';
import ScreenHolder from '../../../Partials/Layouts/ScreenHolder/ScreenHolder';

import './Register.css';
import useAxiosAuthInstance from '../../../../Utilities/Hooks/AxiosInstanceHooks/useAxiosAuthInstance';
import AppContext from '../../../../Context/AppContext';

function Register() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [dp, setDp] = useState(null);

  const { register, employee } = useContext(AppContext);
  const axiosAuthInstance = useAxiosAuthInstance();
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAndSetEmail() {
      try {
        const { data } = await axiosAuthInstance.get(
          `getEmailFromToken/${token}`
        );
        // console.log(data)
        setEmail(data.email);
      } catch (error) {
        console.error('Failed to fetch email:', error);
      }
    }

    fetchAndSetEmail();
  }, [token]);

  function handleSubmit(e) {
    e.preventDefault();

    if (password !== password2) {
      console.log('Passwords do not match!');
    } else {

      // const employeeData = new FormData();

      // // employeeData.append('name', name);
      // // employeeData.append('email', email);
      // // employeeData.append('password', password);
      // // // employeeData.append('dp', dp);
      // // employeeData.append('level', 'employee');
      const employeeData = {name,email,password, level: 'employee'}

      
      register(employeeData);

      setName('');
      setEmail('');
      setPassword('');
      setPassword2('');
      setDp(null);

      if (employee) {
        navigate('/login');
      }
    }
  }
console.log(email)
  return (
    <ScreenHolder>
      <div className='form_wrapper register'>
        <h3>Register</h3>
        <Form onSubmit={handleSubmit} hasImage>
          <ShortTextInput
            label='Name'
            value={name}
            placeholder='Please Enter your Name'
            setState={setName}
          />
          <PasswordInput
            label='Password'
            value={password}
            placeholder='Please Enter your Password'
            setState={setPassword}
          />
          <PasswordInput
            label='Confirm Password'
            value={password2}
            placeholder='Please Confirm your Password'
            setState={setPassword2}
          />
          <ImageInput
            state={dp}
            setState={setDp}
            fieldId='employeeDp'
            children='Display Picture'
            allowCreateImage
          />
          <FormSubmitButton text='Create Account' />
        </Form>
      </div>
    </ScreenHolder>
  );
}

export default Register;
