import {  useContext } from 'react';
import {  MANAGER_API } from '../../../../Utilities/APIs/APIs';
import useAxiosInstance from '../../../../Utilities/Hooks/useAxiosInstance';
import AppContext from '../../../../Context/AppContext';


function MakeCaptain({ targetID, setShowModal, setShowMakeCaptainSection, universityID, universityName }) {

  const { triggerFetch } = useContext(AppContext);
  const axiosInstance = useAxiosInstance();
  async function handleMakeCaptain(id) {
    const response = await axiosInstance.patch(MANAGER_API + id, {
      university: universityID,
      isCaptain: true,
    });

    if (response) {
      setShowModal(false);
      setShowMakeCaptainSection(false);
      triggerFetch();
    }
  }
  return (

    <div className="delete_item">
      <div className="delete_content">
        <h1>Are you sure?</h1>
        <p>Please confirm you want to this manager make captain of {universityName}.</p>
        <div className="btn_container">

          <button
            onClick={() => handleMakeCaptain(targetID)}
            className='confirm'
          >Confirm
          </button>

          <button className='cancel'
            onClick={() => {
              setShowModal(false);
            }}
          >Cancel</button>
        </div>
      </div>
    </div>
  );
}

export default MakeCaptain;
