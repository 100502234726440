import { useState } from "react";
import Form from "../../../Partials/Layouts/Forms/Form";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import useAxiosInstance from "./../../../../Utilities/Hooks/useAxiosInstance";
import { useEffect } from "react";
import SelectInput from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";

function CreateHeroSlider({

  setShowCreateForm,
  setShowModal,
  triggerFetch,
}) {
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState("");
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetEvents() {
      const { data } = await axiosInstance.get(`events?filter=active`);
      setEvents(data);
    }
    fetchAndSetEvents();
  }, [axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault()
    const itemData = new FormData();

    itemData.append("event", event);
    const response = await axiosInstance.post(
      "websiteContents/heroSliders",
      itemData
    );

    if (response) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <SelectInput label={"Select Event"} value={event} setState={setEvent}>
      <SelectOption optionValue={""} optionText={"Select Event"} />
        {events?.map((event) => (
          <SelectOption key={event._id} optionValue={event._id} optionText={event.name} />
        ))}
      </SelectInput>

      <FormSubmitButton text="Create Hero Slider" />
    </Form>
  );
}

export default CreateHeroSlider;
