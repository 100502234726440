import React from 'react'
import { useParams } from 'react-router-dom'
import { RiGroupLine, RiUserShared2Line } from 'react-icons/ri'
import NavCardList from '../../../Partials/Layouts/NavCardList/NavCardList'
import NavCard from '../../../Partials/Layouts/NavCardList/NavCard/NavCard'


function UniversityScreens() {
    const { parentID,universityName } = useParams()

    return (
        <NavCardList numOfCards={'four'}>
            <NavCard cardName={'Captain'} navCardLink={`/university/${parentID}/${universityName}/captain`} ><RiGroupLine /></NavCard>
            <NavCard cardName={'Managers'} navCardLink={`/university/${parentID}/${universityName}/managers`}><RiUserShared2Line /></NavCard>
        </NavCardList>
    )
}

export default UniversityScreens