import { useEffect, useState } from 'react';
import useAxiosInstance from '../../../../Utilities/Hooks/useAxiosInstance';
import Form from '../../../Partials/Layouts/Forms/Form';
import ShortTextInput from '../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput';
import NumberInput from '../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput';
import ImageInput from '../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput';
import FormSubmitButton from '../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton';

function UpdateDraftGroup({ targetID, setShowUpdateForm, setShowModal, triggerFetch }) {
  const [groupName, setGroupName] = useState('');
  const [leagueId, setLeagueId] = useState('');
  const [groupImage, setGroupImage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchGroup() {
      const { data } = await axiosInstance.get(`draft/group/${targetID}`);
      setGroupName(data.groupName);
      setLeagueId(data.leagueId);
      setGroupImage(data?.groupImage)
    }
    fetchGroup();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true)

    const itemData = new FormData()

    itemData.append('groupName', groupName)
    if (leagueId) itemData.append('leagueId', parseInt(leagueId))
    if (groupImage) itemData.append('groupImage', groupImage)

    const response = await axiosInstance.patch(`draft/group/${targetID}`, itemData);
    if (response.data) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch()
      setIsLoading(false)
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <ShortTextInput
        label='Group Name'
        value={groupName}
        placeholder='Enter Group Name'
        setState={setGroupName}
      />
      <NumberInput
        label='League ID'
        value={leagueId}
        placeholder='Enter League ID'
        setState={setLeagueId}
      />
      <ImageInput
        children={"Upload Group Image"}
        fieldId={1}
        state={groupImage}
        setState={setGroupImage}
        allowCreateImage={!groupImage}
        allowUpdateImage={groupImage}
      />

      <FormSubmitButton text='Update Draft Group' isDisabled={isLoading} />
    </Form>
  );
}

export default UpdateDraftGroup;
