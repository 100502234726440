import React, { useState } from "react";
import * as XLSX from "xlsx";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../Partials/Layouts/Forms/Form";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

function CreateGovUsers({ setShowCreateForm, setShowModal, triggerFetch, }) {
    const [data, setData] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");

    const axiosInstance = useAxiosInstance();
    const GOV_USER_API = `gov/createdGovUsers`;

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const binaryStr = e.target.result;
                const workbook = XLSX.read(binaryStr, { type: "binary" });
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);
                setData(jsonData); // Store the parsed data in state
            };
            reader.readAsBinaryString(file);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior

        if (data.length === 0) {
            setResponseMessage("No data to submit.");
            return;
        }

        setIsSubmitting(true); // Start loading state
        setResponseMessage(""); // Clear previous messages

        try {
            const { data: responseData } = await axiosInstance.post(GOV_USER_API, { users: data });

            if (responseData.success) {
                setResponseMessage("Data uploaded successfully!");
                setShowCreateForm(false)
                setShowModal(false)
                triggerFetch()
            }
        } catch (error) {
            setResponseMessage("Error uploading data.");
            console.error("API Error:", error);
        } finally {
            setIsSubmitting(false); // End loading state
        }
    };


    return (
        <div>
            <h3>Google Excel Sheet Upload</h3>
            <Form onSubmit={handleSubmit}>
                <input
                    type="file"
                    accept=".xlsx, .xls"
                    onChange={handleFileUpload}
                    style={{paddingTop: '20px',paddingBottom:'20px'}}
                />
                {data?.length > 0 && (
                    <h3 style={{paddingTop: '5rem'}}>Uploaded Total Data: {data?.length}</h3>
                )}

                <FormSubmitButton
                    text={"Create Managers"}
                    isDisabled={isSubmitting}
                />
            </Form>
            {responseMessage && <p>{responseMessage}</p>}
        </div>
    );
}

export default CreateGovUsers;
