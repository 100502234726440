import { createContext, useState } from 'react';
import useAxiosAuthInstance from '../Utilities/Hooks/AxiosInstanceHooks/useAxiosAuthInstance';
import { LOGIN_API, REGISTER_API } from '../Utilities/APIs/AuthAPIs/AuthAPIs';

const AppContext = createContext({});

function AppContextProvider({ children }) {
  const axiosAuthInstance = useAxiosAuthInstance();
  const [isLoading, setIsLoading] = useState(false);
  const [employee, setEmployee] = useState(
    JSON.parse(localStorage.getItem('employee')),
  );
  const [toggle, setToggle] = useState(false);
  
  function triggerFetch() {
    setToggle(!toggle);
  }

  async function register(employeeData) {
    try {
      const response = await axiosAuthInstance.post(REGISTER_API, employeeData);

      if (response.data) {
        setEmployee(response.data);
        localStorage.setItem('employee', JSON.stringify(response.data));
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  async function login(employeeData) {
    try {
      // setIsLoading(true)
      const response = await axiosAuthInstance.post(LOGIN_API, employeeData);
      if (response.data) {
        setEmployee(response.data);
        localStorage.setItem('employee', JSON.stringify(response.data));
        // setIsLoading(false)
      }
    } catch (error) {}
  }

  function logout() {
    setEmployee(null);
    localStorage.removeItem('employee');
    // navigate('/auth/login')
  }

  return (
    <AppContext.Provider
      value={{
        register,
        login,
        logout,
        employee,
        triggerFetch,
        toggle,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export { AppContextProvider };

export default AppContext;
