import { useContext, useEffect, useState } from 'react';
import AppContext from '../../../../Context/AppContext';
import useAxiosInstance from '../../../../Utilities/Hooks/useAxiosInstance';
import BackButton from '../../../Partials/Elements/BackButton/BackButton';
import CRUDTable from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable';
import CRUDTableHeader from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader';
import CRUDth from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth';
import CRUDTableRow from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow';
import ShortTextCell from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell';
import Modal from '../../../Partials/Elements/Modal/Modal';
import CreateGovUsers from './CreateGovUsers';
import CreateButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton';
import Pagination from '../../../Partials/Elements/Pagination/Pagination';



function GovUsers() {

  const [users, setUsers] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const { setIsLoading, toggle, triggerFetch } = useContext(AppContext);
  const axiosInstance = useAxiosInstance();
  const [showCreateForm, setShowCreateForm] = useState(false)
  const [totalUsers, setTotalUsers] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    async function fetchAndSetCustomers() {
      const { data } = await axiosInstance.get(`gov/getAllGovUsers?page=${currentPage}`);
      setUsers(data?.users);
      setTotalPages(data?.pagination?.totalPages)
      setTotalUsers(data?.pagination?.totalUsers)
    }
    fetchAndSetCustomers();
  }, [axiosInstance, setIsLoading, toggle, currentPage]);



  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false)
  }

  return (
    <>
      <div className='crud_holder'>
        <div className='body_header'>
          <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <BackButton />
            <h1 className='heading'>{`GOV Users  (${totalUsers} in total)`}</h1>
          </div>
          <CreateButton
            screenTopicSingular='GOV Users'
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable fullView={false}>
          <CRUDTableHeader>
            <CRUDth th='Manager Name' />
            <CRUDth th='FplId' />
            <CRUDth th='Team Name' />
            <CRUDth th='Over All Rank' />
            <CRUDth th="Is Qualified" />
          </CRUDTableHeader>
          <tbody>
            {users?.map((customer) => (
              <CRUDTableRow key={customer?._id}>
                <ShortTextCell text={customer?.playerName} />
                <ShortTextCell text={customer?.fplId} />
                <ShortTextCell text={customer?.teamName} />
                <ShortTextCell text={customer?.overAllRank} />
                <ShortTextCell text={customer?.isDisQualified ? " No" : "Yes"} />
              </CRUDTableRow>
            ))}
          </tbody>



        </CRUDTable>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={(newPage) => setCurrentPage(newPage)} // Pass the new page number here
        />
      </div>


      {showModal && showCreateForm && (
        <Modal
          handleClose={handleClose}
          modalHeading={showCreateForm && `Create Managers`}
        >
          {showCreateForm &&
            <CreateGovUsers
              targetID={targetID}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />}
        </Modal>
      )}

    </>
  );
}

export default GovUsers;

