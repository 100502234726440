import { useEffect, useState } from "react";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import SwitchInput from "../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput";
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import NumberInput from "../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";

function UpdateRoundGameweek({ targetID, setShowUpdateForm, setShowModal }) {
    const [roundName, setRoundName] = useState("");
    const [numberOfGameweeks, setNumberOfGameweeks] = useState(0);
    const [gameweeks, setGameweeks] = useState([]);
    const [error, setError] = useState(""); // Error state for validation messages
    const axiosInstance = useAxiosInstance();
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        async function fetchRoundGameweek() {
            const { data } = await axiosInstance.get(`/borRoundGw/${targetID}`);
            setRoundName(data?.roundName);
            setNumberOfGameweeks(data?.numberOfGameweeks);
            setGameweeks(data?.gameweeks);
        }
        fetchRoundGameweek();
    }, [targetID, axiosInstance]);

    function handleGameweekChange(index, key, value) {
        const updatedGameweeks = [...gameweeks];
        updatedGameweeks[index][key] = value;
        setGameweeks(updatedGameweeks);
    }

    function validateForm() {
        const matchNumbers = {}; // Object to track occurrences of match numbers

        for (const [index, gw] of gameweeks.entries()) {
            if (!gw?.gameweek || !gw?.matchNumber) {
                return `Error: Gameweek ${index + 1} must have a gameweek number and match number.`;
            }

            if (matchNumbers[gw.matchNumber]) {
                return `Error: Match Number ${gw.matchNumber} is duplicated in Gameweek ${index + 1}. Each match number must be unique.`;
            }

            matchNumbers[gw.matchNumber] = true; // Mark match number as seen
        }

        return ""; // No errors
    }

    async function handleSubmit(e) {
        e.preventDefault();
        setDisabled(true);
        setError("")
        // Perform validation
        const validationError = validateForm();
        if (validationError) {
            setError(validationError); // Set error message
            setDisabled(false);
            return; // Prevent submission
        }

        setError(""); // Clear errors if validation passes
        const payload = { gameweeks };

        const response = await axiosInstance.patch(`/borRoundGw/${targetID}`, payload);

        if (response.data) {
            setShowUpdateForm(false);
            setShowModal(false);
            setDisabled(false);
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <ShortTextInput
                label="Round Name"
                value={roundName}
                placeholder="Enter Round Name"
                readonly={true}
            />
            <ShortTextInput
                label="Number of Gameweeks"
                value={numberOfGameweeks}
                placeholder="Enter Number of Gameweeks"
                readonly={true}
            />
            {gameweeks.map((gw, index) => (
                <div
                    key={index}
                    style={{
                        marginBottom: "20px",
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                    }}
                >
                    <NumberInput
                        label={`Gameweek ${index + 1}`}
                        value={gw?.gameweek}
                        placeholder={`Enter Gameweek for Match ${gw.matchNumber}`}
                        setState={(value) =>
                            handleGameweekChange(index, "gameweek", value)
                        }
                    />
                    <div>
                        <label
                            className="input_field_label caption bold"
                        >{`Match ${index + 1}`}</label>

                        <select
                            className="input_field"
                            value={gw?.matchNumber}
                            onChange={(e) =>
                                handleGameweekChange(
                                    index,
                                    "matchNumber",
                                    e.target.value
                                )
                            }
                            aria-label="Select Match Number"
                        >
                            <option value="" disabled>
                                Select Match Number
                            </option>


                            {roundName === "Grand Final"
                                ? (
                                    <option value={1}>Match 1</option>
                                )
                                : [1, 2, 3].map((matchNumber) => (
                                    <option key={matchNumber} value={matchNumber}>
                                        Match {matchNumber}
                                    </option>
                                ))}
                        </select>
                    </div>

                    <SwitchInput
                        label="Transfers Cost"
                        checked={gw?.allowedTransferCost} // Bind to the current state value
                        toggleSwitch={(value) =>
                            handleGameweekChange(
                                index,
                                "allowedTransferCost",
                                value
                            )
                        } // Update state when toggled
                    />

                    <SwitchInput
                        label="Allow Chips"
                        checked={gw?.allowedChip}
                        toggleSwitch={(value) =>
                            handleGameweekChange(index, "allowedChip", value)
                        }
                    />
                </div>
            ))}
            {error && <p style={{ color: "red", marginBottom: "20px" }}>{error}</p>}
            <FormSubmitButton text="Update Round Gameweek" isDisabled={disabled}/>
        </Form>
    );
}

export default UpdateRoundGameweek;
