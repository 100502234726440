import { useEffect, useState } from 'react';
import FilterSelect from '../../../Partials/Layouts/Forms/FilterSelect/FilterSelect';

import BackButton from '../../../Partials/Elements/BackButton/BackButton';
import CRUDTable from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable';
import CRUDth from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth';
import CRUDTableRow from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow';
import ShortTextCell from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell';
import useAxiosInstance from '../../../../Utilities/Hooks/useAxiosInstance';
import CRUDTableHeader from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader';


function DraftAllUsers() {

    const [users, setUsers] = useState([])
    const [filter, setFilter] = useState("qualified")
    const [selectedRound, setSelectedRound] = useState('')
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        async function fetchGroups() {
            const { data } = await axiosInstance.get(`draft/getAllGroupsUsers?filter=${filter}`);
            setUsers(data?.users);
            setSelectedRound(data?.selectedRound)
        }
        fetchGroups();
    }, [filter]);

    const qualifiedFilters = [
        { label: 'Qualified', value: 'qualified' },
        { label: 'Disqualified', value: 'disqualified' },
    ]

    const roundFilters = [
        { label: 'R1', value: 'R1' },
        { label: 'R2', value: 'R2' },
        { label: 'R3', value: 'R3' },
        { label: 'Final', value: 'Final' },
    ]

    return (
        <>
            <div className='crud_holder'>
                <div className="body_header">
                    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                        <BackButton />
                        <h1 className="heading">{`Users (${users?.length || 0} in total)`}</h1>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                        <FilterSelect filter={selectedRound} setFilter={setSelectedRound} filters={roundFilters} />
                        <FilterSelect filter={filter} setFilter={setFilter} filters={qualifiedFilters} />
                    </div>

                </div>
                <CRUDTable>
                    <CRUDTableHeader>
                        <CRUDth th='Group' />
                        <CRUDth th='Manager' />
                        <CRUDth th='Team' />
                        <CRUDth th='Draft Id' />
                        <CRUDth th='Total Points' />
                        {filter === 'disqualified' && <CRUDth th='Disqualified Round' />}
                    </CRUDTableHeader>
                    <tbody>
                        {users?.length > 0 && users?.map((user) => (
                            <CRUDTableRow key={user._id}>
                                <ShortTextCell text={user?.group?.groupName} />
                                <ShortTextCell text={user?.playerName} />
                                <ShortTextCell text={user?.teamName} />
                                <ShortTextCell text={user?.draftId} />
                                <ShortTextCell text={user?.totalPoints} />
                                {filter === 'disqualified' && <ShortTextCell text={user?.disqualifiedRound} />}
                            </CRUDTableRow>
                        ))}
                    </tbody>
                </CRUDTable>
            </div>

        </>
    );
}

export default DraftAllUsers;
