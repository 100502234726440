import { useState } from "react";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import SwitchInput from "../../Partials/Layouts/Forms/FormInputs/SwitchInput/SwitchInput";
import ImageInput from "../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import NumberInput from "../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import SelectInput from "../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";




function CreateUniversity({ setShowCreateForm, setShowModal, triggerFetch, countryId }) {

  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [tier, setTier] = useState("");


  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();


    // const itemData = new FormData();

    // itemData.append("name", name);
    // // itemData.append("image", image);
    // itemData.append("tier", tier);

    const itemData = {name,tier}

    console.log(itemData)

    const response = await axiosInstance.post("university/createUniversity", itemData);

    if (response) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`University Name`}
        value={name}
        placeholder={`Enter University Name`}
        setState={setName}
      />
      <SelectInput
        value={tier}
        setState={setTier}
        label={"Select Tier"}

      >
        <SelectOption optionText={"Select Tier"} optionValue={""} />
        <SelectOption optionText={"1"} optionValue={"1"} />
        <SelectOption optionText={"2"} optionValue={"2"} />
      </SelectInput>

      {/* <ImageInput
        children={"Upload University Logo"}
        fieldId={1}
        state={image}
        setState={setImage}
        allowCreateImage
      /> */}
      <FormSubmitButton text="Create University" />
    </Form>
  );
}

export default CreateUniversity;
