export const IMAGE_URL = process.env.REACT_APP_SPACES_URL;

// employee
export const ALL_EMPLOYESS_API = 'manageEmployees/';

export const MANAGER_API = 'manageManagers/';

export const ALL_MANAGERS_CAPTAIN_BY_UNIVERISTY = MANAGER_API + 'getManagerAndCaptainByUniversity'

// Invite employess
// export const ALL_EMPLOYEE_INVITES_API = 'employeeInvite/'

export const MANAGE_EMPLOYEE_API = 'manageEmployees/';
// customers
export const ALL_MANAGER_API = 'manageManagers/';

export const ORDERS_API = 'orders/';
