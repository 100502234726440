import { useEffect, useState } from "react";
import Form from "../../../Partials/Layouts/Forms/Form";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import useAxiosInstance from "./../../../../Utilities/Hooks/useAxiosInstance";

import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import NumberInput from "../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";

function UpdateAds({
  targetID,
  employee,
  setShowUpdateForm,
  setShowModal,
  triggerFetch,
}) {
  const [link, setLink] = useState("");
  const [poster, setPoster] = useState(null);
  const [precedence, setPrecedence] = useState(null);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetAds() {
      const { data } = await axiosInstance.get("ads/getSingleAds/" + targetID);
      setLink(data.link);
      setPoster(data?.poster);
      setPrecedence(data?.precedence)
    }
    fetchAndSetAds();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = new FormData();

    itemData.append("link", link);
    itemData.append("poster", poster);
    const response = await axiosInstance.patch("ads/" + targetID, itemData);

    if (response.data) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Poster Link`}
        value={link}
        placeholder={`Enter Link`}
        setState={setLink}
      />
      <NumberInput
        label={`Enter Precedence`}
        value={precedence}
        placeholder={`Enter Precedence`}
        setState={setPrecedence}
      />
      <ImageInput
        fieldId="1"
        state={poster}
        setState={setPoster}
        allowUpdateImage
      />

      <FormSubmitButton text="Update Ads" />
    </Form>
  );
}

export default UpdateAds;
