import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../Partials/Layouts/Forms/Form";
import SelectInput from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import NumberInput from "../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

function UpdateGovSetting({ govSettings, targetID, setShowUpdateForm, setShowModal, triggerFetch }) {
    const [startGameweek, setStartGameweek] = useState("");
    const [endGameweek, setEndGameweek] = useState("");
    const [maxTransfer, setMaxTransfer] = useState(0); // Updated field name based on schema
    const axiosInstance = useAxiosInstance();


    useEffect(() => {
        // Find the specific gov setting based on targetID
        const currentSetting = govSettings.find((setting) => setting._id === targetID);

        if (currentSetting) {
            setStartGameweek(currentSetting.startGameweek);
            setEndGameweek(currentSetting.endGameweek);
            setMaxTransfer(currentSetting.maxTransfer);
        }
    }, [targetID, govSettings]); // Dependencies: targetID and govSettings


    async function handleSubmit(e) {
        e.preventDefault();
        const payload = { startGameweek, endGameweek, maxTransfer };
        const response = await axiosInstance.patch("gov/govSetting/" + targetID, payload); // Updated endpoint

        if (response.data) {
            setShowUpdateForm(false);
            setShowModal(false);
            triggerFetch();
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            {/* Start Gameweek */}
            <SelectInput
                label="Start Gameweek"
                value={startGameweek}
                setState={setStartGameweek}
            >
                <SelectOption optionText={"Select Start GW"} optionValue={""} />
                {[...Array(22)].map((_, i) => (
                    <SelectOption key={i + 17} optionText={i + 17} optionValue={i + 17} />
                ))}
            </SelectInput>

            {/* End Gameweek */}
            <SelectInput
                label="End Gameweek"
                value={endGameweek}
                setState={setEndGameweek}
            >
                <SelectOption optionText={"Select End GW"} optionValue={""} />
                {[...Array(22)].map((_, i) => (
                    <SelectOption key={i + 17} optionText={i + 17} optionValue={i + 17} />
                ))}
            </SelectInput>

            {/* Max Transfers */}
            <NumberInput
                label="Max Transfers"
                value={maxTransfer}
                placeholder="Enter Max Transfers"
                setState={setMaxTransfer}
            />

            {/* Submit Button */}
            <FormSubmitButton text="Update Gameweek Settings" />
        </Form>
    );
}

export default UpdateGovSetting;
