import SmallSideBarItem from './SmallSideBarItem/SmallSideBarItem';
import { RiGroupLine, RiHome2Line, } from 'react-icons/ri';
import { GiLaurelsTrophy } from 'react-icons/gi'; // OR
import { FaMedal } from 'react-icons/fa';
import { GiCrossedSwords } from 'react-icons/gi';
import { GiSoccerBall, GiSoccerField } from 'react-icons/gi'; // Game Icons


import './SideBar.css';

function SideBar() {
  return (
    <>
      <section className='small_sidebar'>
        <div className='container'>
          <SmallSideBarItem link='/' dataTip='Home Page' dataFor='dashboard'>
            <RiHome2Line />
          </SmallSideBarItem>

          <SmallSideBarItem link='/borGameweek' dataTip='BOR' dataFor='Bor Gameweek'>
            <GiCrossedSwords />
          </SmallSideBarItem>

          <SmallSideBarItem link='/lscDashboard' dataTip='LSC' dataFor='LSC'>
            <GiLaurelsTrophy />
          </SmallSideBarItem>
          <SmallSideBarItem link='/govDashboard' dataTip='GOV' dataFor='GOV'>
            <FaMedal />
          </SmallSideBarItem>
          <SmallSideBarItem link='/draftDashboard' dataTip='Draft' dataFor='Draft'>
            <GiSoccerBall />
          </SmallSideBarItem>
          {/* 
          <SmallSideBarItem link='/usersScreen' dataTip='Users' dataFor='Users'>
            <RiGroupLine />
          </SmallSideBarItem>

          <SmallSideBarItem
            link='/university'
            dataTip='University'
            dataFor='University'
          >
            <FaUniversity />
          </SmallSideBarItem> */}

          {/* <SmallSideBarItem
            link='/appSettings'
            dataTip='App Settings'
            dataFor='App Settings'
          >
            <RiSettings2Line />
          </SmallSideBarItem> */}

        </div>
      </section>
    </>
  );
}

export default SideBar;
