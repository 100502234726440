import { useEffect, useState } from "react";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import ImageInput from "../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import SelectInput from "../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";


function UpdateUniversity({
    targetID,
    setShowUpdateForm,
    setShowModal,
    triggerFetch,
}) {
    const [name, setName] = useState("");
    const [image, setImage] = useState("");
    const [tier, setTier] = useState("");
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        async function fetchAndSetAds() {
            const { data } = await axiosInstance.get("university/getSingleUniversity/" + targetID);
            console.log(data)
            setName(data?.name);
            setImage(data?.image)
            setTier(data?.tier)
        }
        fetchAndSetAds();
    }, [targetID, axiosInstance]);

    async function handleSubmit(e) {

        e.preventDefault();

        // const itemData = new FormData();
        // itemData.append("name", name);
        // // itemData.append("image", image);
        // itemData.append("tier", tier);

        const itemData = { name, tier }

        const response = await axiosInstance.patch("university/updateUniversity/" + targetID, itemData);

        if (response.data) {
            setShowUpdateForm(false);
            setShowModal(false);
            triggerFetch();
        }
    }

    return (
        <Form onSubmit={handleSubmit} hasImage>
            <ShortTextInput
                label={`University Name`}
                value={name}
                placeholder={`Enter University Name`}
                setState={setName}
            />
            <SelectInput
                value={tier}
                setState={setTier}
                label={"Select Tier"}
            >
                <SelectOption optionText={"Select Tier"} optionValue={""} />
                <SelectOption optionText={"1"} optionValue={"1"} />
                <SelectOption optionText={"2"} optionValue={"2"} />
            </SelectInput>

            {/* <ImageInput
                children={"Upload University Logo"}
                fieldId={1}
                state={image}
                setState={setImage}
                allowUpdateImage
            /> */}
            <FormSubmitButton text="Update University" />
        </Form>
    );
}

export default UpdateUniversity;
