import InputBoilerplate from "../InputBoilerplate";

function ShortTextInput({ label, value, placeholder, setState, readonly = false }) {
  return (
    <InputBoilerplate
      label={label}
      type={'text'}
      value={value}
      placeholder={placeholder}
      setState={setState}
      readonly={readonly}
    />
  )
}

export default ShortTextInput