import { useContext, useEffect, useState } from 'react';
import AppContext from '../../../Context/AppContext';
import useAxiosInstance from '../../../Utilities/Hooks/useAxiosInstance';
import { useNavigate } from 'react-router-dom';
import BackButton from '../../Partials/Elements/BackButton/BackButton';
import FilterSelect from '../../Partials/Layouts/Forms/FilterSelect/FilterSelect';
import CreateButton from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton';
import CRUDTable from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable';
import CRUDTableHeader from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader';
import CRUDth from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth';
import CRUDTableRow from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow';
import ShortTextCell from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell';
import EditButton from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton';
import ArchiveButton from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ArchiveButton/ArchiveButton';
import CRUDButton from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton';
import { FiImage } from 'react-icons/fi';
import Modal from '../../Partials/Elements/Modal/Modal';
import ArchiveItem from '../../Partials/Layouts/ArchiveItem/ArchiveItem';
import CreateUniversity from './CreateUniversity';
import UpdateUniversity from './UpdateUniversity';
import ImageCell from '../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell';

function University() {
  const [universities, setUniversities] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AppContext);
  const [archive, setArchive] = useState(null);
  const [filter, setFilter] = useState('active');
  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAndSatUniversities() {
      const { data } = await axiosInstance.get(`university/university?filter=${filter}`);
      setUniversities(data);
    }
    fetchAndSatUniversities();
  }, [toggleFetch, filter]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className='crud_holder'>
        <div className='body_header'>
          <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <BackButton />
            <h1 className='heading'>{`Universities (${universities?.length} in total)`}</h1>
          </div>
          <FilterSelect filter={filter} setFilter={setFilter} />
          <CreateButton
            screenTopicSingular='University'
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th='Name' />
            {/* <CRUDth th='Image' /> */}
            <CRUDth th='Actions' />
          </CRUDTableHeader>
          <tbody>
            {universities?.length > 0 && universities?.map((university) => (
              <CRUDTableRow key={university._id}>
                <ShortTextCell text={university?.universityName} />
                {/* <ImageCell imgSrc={university?.image} /> */}

                <td className='action_button_cell'>
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={university._id}
                    setTargetID={setTargetID}
                  />

                  <ArchiveButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={university._id}
                    isArchive={university?.isArchive}
                    setTargetID={setTargetID}
                    setArchive={setArchive}
                  />

                  <CRUDButton
                    data-for='View Managers'
                    data-tip='View Managers'
                    children={<FiImage />}
                    handleClick={() =>
                      navigate(`/universityScreen/${university?.name}/${university?._id}` )
                    }

                  />
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create University`) ||
            (showUpdateForm && `Update University`) ||
            (showDeleteSection && `Archive University`)
          }
        >
          {showCreateForm && (
            <CreateUniversity
              employee={employee}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateUniversity
              employee={employee}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <ArchiveItem
              api={'university/archiveUniversity/'}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
              isArchive={archive}
            />
          )}
        </Modal>
      )}
    </>
  );
}

export default University;
