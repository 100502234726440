import { FiLoader } from 'react-icons/fi';
import './FormSubmitButton.css';

function FormSubmitButton({ text, isDisabled }) {
  return (
    <div className="btn_wrapper">
      <button type="submit" className="submit w_100" disabled={isDisabled}>
        {isDisabled ? <FiLoader className='btn_loader' /> : text}
      </button>
    </div>
  );
}

export default FormSubmitButton;
