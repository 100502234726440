import {  RiGroup2Line, RiGroupLine } from "react-icons/ri"
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard"
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList"

function EmployeesScreen() {
  return (
    <NavCardList numOfCards={'two'}>
        <NavCard cardName={'Employeees'} navCardLink='/employees' ><RiGroup2Line /></NavCard>
        <NavCard cardName={'Employee Invites'} navCardLink='/employeeInvites' ><RiGroupLine /></NavCard>
    </NavCardList>
  )
}

export default EmployeesScreen