import { useContext, useEffect, useState } from 'react';
import AppContext from '../../../../Context/AppContext';
import useAxiosInstance from '../../../../Utilities/Hooks/useAxiosInstance';
import BackButton from '../../../Partials/Elements/BackButton/BackButton';
import CRUDTable from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable';
import CRUDTableHeader from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader';
import CRUDth from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth';
import CRUDTableRow from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow';
import ShortTextCell from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell';
import Modal from '../../../Partials/Elements/Modal/Modal';
import EditButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton';
import UpdateLscGameweek from './UpdateLscGameweek';



function LscUsers() {

  const [gameweeks, setGameweeks] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const { setIsLoading, toggle, triggerFetch } = useContext(AppContext);
  const axiosInstance = useAxiosInstance();
  const [showUpdateForm, setShowUpdateForm] = useState(false)

  useEffect(() => {
    async function fetchAndSetCustomers() {
      const { data } = await axiosInstance.get(`lsc/getAllLscGw`);
      setGameweeks(data);
    }
    fetchAndSetCustomers();
  }, [axiosInstance, setIsLoading, toggle]);



  function handleClose() {
    setShowModal(false);
    setShowUpdateForm(false)
  }

  return (
    <>
      <div className='crud_holder'>
        <div className='body_header'>
          <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <BackButton />
            <h1 className='heading'>{`Lsc Gameweeks  (${gameweeks?.length > 0 ? gameweeks?.length : 0} in total)`}</h1>
          </div>

        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th='Gameweek' />
            <CRUDth th='Disqualified Numbers' />
            <CRUDth th='Actions' />

          </CRUDTableHeader>
          <tbody>
            {gameweeks?.map((gw) => (
              <CRUDTableRow key={gw?._id}>
                <ShortTextCell text={gw?.gameWeek} />
                <ShortTextCell text={gw?.disqualifiedNumbers} />

                <td className='action_button_cell'>
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={gw._id}
                    setTargetID={setTargetID}
                  />
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>

      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            (showUpdateForm && `Update Lsc Gameweek`) || `Details`
          }
        >
          {showUpdateForm && (
            <UpdateLscGameweek
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
              allGameweks={gameweeks}
            />
          )}
        </Modal>
      )}

    </>
  );
}

export default LscUsers;
