
import { RiInboxArchiveLine } from 'react-icons/ri';

import './ArchiveButton.css';
import CRUDButton from '../CRUDButton';


function ArchiveButton({setShowModal, setShowDeleteSection, targetID, setTargetID,isArchive,setArchive }) {
  return (
    <CRUDButton
      dataTip='Archive'
      dataFor='Archive Item'
      handleClick={() => {
        setShowModal(true)
        setShowDeleteSection(true)
        setTargetID(targetID)
        setArchive(isArchive)
      }}
      deleteButton
    >
     <RiInboxArchiveLine />

    </CRUDButton>
  )
}

export default ArchiveButton