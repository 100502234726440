import { useState, useEffect, useContext } from "react";
// import { useSelector } from "react-redux"


import { IMAGE_URL } from "../../../../Utilities/APIs/APIs";
import ImageCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell";
import CreateButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import Modal from "../../../Partials/Elements/Modal/Modal";
import ViewHeroSlider from "./ViewHeroSlider";
import CreateHeroSlider from "./CreateHeroSlider";
import UpdateHeroSlider from "./UpdateHeroSlider";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import BackButton from "../../../Partials/Elements/BackButton/BackButton";
import FilterSelect from "../../../Partials/Layouts/Forms/FilterSelect/FilterSelect";
import ArchiveButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ArchiveButton/ArchiveButton";
import ArchiveItem from "../../../Partials/Layouts/ArchiveItem/ArchiveItem";
import AppContext from "../../../../Context/AppContext";

function HeroSliders() {
  const [heroSliders, setHeroSliders] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AppContext);
  const [archive, setArchive] = useState(null);
  const [filter, setFilter] = useState("active");
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetHeroSliders() {
      const { data } = await axiosInstance.get(
        `${"websiteContents/heroSliders"}?filter=${filter}`
      );
      setHeroSliders(data);
    }
    fetchAndSetHeroSliders();
  }, [toggleFetch, axiosInstance, filter]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <BackButton />
            <h1 className="heading">{`Hero Sliders (${heroSliders?.length} in total)`}</h1>
          </div>
          <FilterSelect filter={filter} setFilter={setFilter} />
          <CreateButton
            screenTopicSingular="Hero Slider"
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
          <CRUDth th="Name" />
            <CRUDth th="Description" />
            <CRUDth th="Location" />
            <CRUDth th="Organizer" />
            <CRUDth th="Type" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {heroSliders?.map((heroSlider) => (
              <CRUDTableRow key={heroSlider._id}>
               <ShortTextCell text={heroSlider?.event?.name} />
                <ShortTextCell text={heroSlider?.event?.description.slice(0,80) + "..."} />
                <ShortTextCell text={heroSlider?.event?.location} />
                <ShortTextCell text={heroSlider?.event?.organizer} />
                <ShortTextCell text={heroSlider?.event?.type} />


                <td className="action_button_cell">
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={heroSlider._id}
                    setTargetID={setTargetID}
                  />
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={heroSlider._id}
                    setTargetID={setTargetID}
                  />
                  {/* <DeleteButton
                                        setShowModal={setShowModal}
                                        setShowDeleteSection={setShowDeleteSection}
                                        targetID={heroSlider._id}
                                        setTargetID={setTargetID}
                                    /> */}

                  <ArchiveButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={heroSlider._id}
                    isArchive={heroSlider?.isArchive}
                    setTargetID={setTargetID}
                    setArchive={setArchive}
                  />
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Hero Slider`) ||
            (showUpdateForm && `Update Hero Slider`) ||
            (showDeleteSection && `Archive Hero Slider`)
          }
        >
          {showCreateForm && (
            <CreateHeroSlider
              employee={employee}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateHeroSlider
              employee={employee}
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
          
            <ArchiveItem
              api={"websiteContents/heroSliders/archiveHeroSlider/"}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              triggerFetch={triggerFetch}
              isArchive={archive}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Hero Slider`}
        >
          <ViewHeroSlider targetID={targetID} employee={employee} />
        </Modal>
      )}
    </>
  );
}

export default HeroSliders;
