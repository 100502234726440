

import NavCardList from '../../../Partials/Layouts/NavCardList/NavCardList'
import NavCard from '../../../Partials/Layouts/NavCardList/NavCard/NavCard'
import { RiGroupLine } from 'react-icons/ri'
import { FaCalendarAlt } from 'react-icons/fa';


function LscDashboard() {

  return (
    <NavCardList numOfCards={'four'}>
      <NavCard cardName={'Lsc Users'} navCardLink='/lscUsers' ><RiGroupLine /></NavCard>
      <NavCard cardName={'Lsc Gameweeks'} navCardLink='/lscGameweeks' ><FaCalendarAlt /></NavCard>
    </NavCardList>
  )
}

export default LscDashboard