import { useContext, useEffect, useState } from 'react';
import { GiCaptainHatProfile } from 'react-icons/gi';
import { useParams } from 'react-router-dom';
import AppContext from '../../../../Context/AppContext';

import Modal from '../../../Partials/Elements/Modal/Modal';
import ViewButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton';
import CRUDTable from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable';
import ShortTextCell from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell';
import CRUDTableHeader from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader';
import CRUDth from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth';
import CRUDTableRow from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow';
import { ALL_MANAGERS_CAPTAIN_BY_UNIVERISTY, CUSTOMER_API, MANAGER_API } from './../../../../Utilities/APIs/APIs';
import useAxiosInstance from '../../../../Utilities/Hooks/useAxiosInstance';
import BackButton from '../../../Partials/Elements/BackButton/BackButton';
import MakeCaptain from '../../UsersScreen/Managers/MakeCaptain';
import CaptainButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/captainButton/captainButton';
import ViewManager from '../../UsersScreen/Managers/ViewManager';
import CRUDButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton';
import { IoPersonRemoveSharp } from 'react-icons/io5';
import RemoveCaptain from '../../UsersScreen/Managers/RemoveCaptain';
function UniversityManagers() {

  const { universityID, level,universityName } = useParams();

  const [managers, setManagers] = useState(null);
  const [hasCaptain,setHasCaptain] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const { setIsLoading, toggle ,triggerFetch } = useContext(AppContext);
  const axiosInstance = useAxiosInstance();
  const [showMakeCaptainSection, setShowMakeCaptainSection] = useState(false);
  const [showRemoveCaptainSection, setShowRemoveCaptainSection] = useState(false);

  const isCaptain = level === "captain" ? true : false;

  useEffect(() => {
    async function fetchAndSetManagersAndCaptains() {
      const { data } = await axiosInstance.get(
        `${ALL_MANAGERS_CAPTAIN_BY_UNIVERISTY}?university=${universityID}&isCaptain=${isCaptain}`
      );
      setManagers(data?.managers);
      setHasCaptain(data?.hasCaptain)
    }
    fetchAndSetManagersAndCaptains();
  }, [universityID, isCaptain, axiosInstance, setIsLoading, toggle]);

  function handleClose() {
    setShowModal(false);
    setShowViewSection(false);
    setShowMakeCaptainSection(false);
  }

  return (
    <>
      <div className='crud_holder'>
        <div className='body_header'>
          <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <BackButton />

            <h1 className='heading'>{`${universityName} ${isCaptain ? "Captain" : "Managers"} (${managers?.length > 0 ? managers.length : 0
              } in total)`}</h1>
          </div>

        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th='Manager Name' />
            <CRUDth th='Email' />
            <CRUDth th='Actions' />
          </CRUDTableHeader>
          <tbody>
            {managers?.length > 0 && managers?.map((manager) => (
              <CRUDTableRow key={manager._id}>
                <ShortTextCell text={manager.name} />

                <ShortTextCell text={manager.email} />

                <td className='action_button_cell'>
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={manager._id}
                    setTargetID={setTargetID}
                  />

                  {!manager?.isCaptain && !hasCaptain &&
                    <CaptainButton
                      setShowModal={setShowModal}
                      setShowMakeCaptainSection={setShowMakeCaptainSection}
                      targetID={manager._id}
                      setTargetID={setTargetID}
                      isCaptain={manager.isCaptain}
                    />
                  }

                  {manager.isCaptain &&
                    <CRUDButton
                      dataTip='Remove Captain'
                      dataFor='Remove Captain'
                      handleClick={() => {
                        setShowModal(true);
                        setShowRemoveCaptainSection(true);
                        setTargetID(manager?._id);
                      }}
                    >
                      <IoPersonRemoveSharp style={{ color: 'red' }} />
                    </CRUDButton>
                  }

                </td>

              </CRUDTableRow>
            ))}

          </tbody>
        </CRUDTable>
      </div>

      {showModal && showViewSection && (
        <Modal
          handleClose={handleClose}
          modalHeading={showViewSection && `View Customer`}
        >
          {showViewSection && <ViewManager targetID={targetID} />}
        </Modal>
      )}

      {showModal && showMakeCaptainSection && (
        <Modal
          handleClose={handleClose}
          modalHeading={showMakeCaptainSection && `Make Captain`}
        >
          {showMakeCaptainSection && (
            <MakeCaptain
              targetID={targetID}
              setShowModal={setShowModal}
              setShowMakeCaptainSection={setShowMakeCaptainSection}
              universityID={universityID}
              universityName={universityName}
            />
          )}
        </Modal>
      )}

      {showModal && showRemoveCaptainSection && (
        <Modal
          handleClose={handleClose}
          modalHeading={showRemoveCaptainSection && `Remove Captain`}
        >
          {showRemoveCaptainSection && (
            <RemoveCaptain
              targetID={targetID}
              setShowModal={setShowModal}
              setShowRemoveCaptainSection={setShowRemoveCaptainSection}
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}
    </>
  );
}

export default UniversityManagers;
