import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CRUDth from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth';
import CreateDraftGroup from './createDraftGroups';
import UpdateDraftGroup from './updateDraftGroups';
import Modal from '../../../Partials/Elements/Modal/Modal';
import CRUDTableRow from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow';
import ShortTextCell from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell';
import EditButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton';
import CRUDTableHeader from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader';
import CRUDTable from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable';
import CreateButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton';
import BackButton from '../../../Partials/Elements/BackButton/BackButton';
import useAxiosInstance from '../../../../Utilities/Hooks/useAxiosInstance';
import ImageCell from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell';
import { FiExternalLink } from 'react-icons/fi';
import CRUDButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton';


function DraftRoundGroups() {
  const { roundId } = useParams()
  const [groups, setGroups] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [toggleFetch, setToggleFetch] = useState(false);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchGroups() {
      const { data } = await axiosInstance.get(`draft/groups?round=${roundId}`);
      setGroups(data);
    }
    fetchGroups();
  }, [roundId, toggleFetch]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowUpdateForm(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }
  const navigate = useNavigate()
  return (
    <>
      <div className='crud_holder'>
        <div className='body_header'>
          <BackButton />
          <h1 className='heading'>{`Draft Round Groups (${groups?.length})`}</h1>
          <CreateButton
            screenTopicSingular='Draft Round Group'
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th='Round Name' />
            <CRUDth th='Group Name' />
            <CRUDth th='League ID' />
            <CRUDth th='Group Image' />
            <CRUDth th='Actions' />
          </CRUDTableHeader>
          <tbody>
            {groups.map((group) => (
              <CRUDTableRow key={group._id}>
                <ShortTextCell text={group?.round?.roundName} />
                <ShortTextCell text={group?.groupName} />
                <ShortTextCell text={group?.leagueId} />
                <ImageCell imgSrc={group?.groupImage} imgAlt={'Group Image'} />
                <td className='action_button_cell'>
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={group._id}
                    setTargetID={setTargetID}
                  />

                  <CRUDButton
                    dataTip='Group Users'
                    dataFor='Group Users'
                    handleClick={() => {
                      navigate(`/draftGroup/users/${group._id}`)
                    }}
                  >
                    <FiExternalLink />
                  </CRUDButton>

                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && 'Create Draft Group') ||
            (showUpdateForm && 'Update Draft Group')
          }
        >
          {showCreateForm && (
            <CreateDraftGroup
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              round={roundId}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateDraftGroup
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}
    </>
  );
}

export default DraftRoundGroups;
