import { useState } from "react";
import Form from "../../../Partials/Layouts/Forms/Form";
import { HERO_SLIDERS_API } from "../../../../Utilities/APIs/APIs";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import NumberInput from "../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import { useEffect } from "react";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import SelectInput from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";

function UpdateHeroSlider({
  targetID,
  employee,
  setShowUpdateForm,
  setShowModal,
  triggerFetch,
}) {
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState(null);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndsetProductInfo() {
      const { data } = await axiosInstance.get(
        "websiteContents/heroSliders/getSingleHeroSlider/" + targetID
      );
      setEvent(data.event._id);
    }
    fetchAndsetProductInfo();
  }, [targetID, axiosInstance]);

  useEffect(() => {
    async function fetchAndSetEvents() {
      const { data } = await axiosInstance.get(`events?filter=active`);
      setEvents(data);
    }
    fetchAndSetEvents();
  }, [axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = new FormData();
    itemData.append("event", event);
    const response = await axiosInstance.patch(
      "websiteContents/heroSliders/" + targetID,
      itemData
    );

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <SelectInput label={"Select Event"} value={event} setState={setEvent}>
        {events?.map((event) => (
          <SelectOption optionValue={event._id} optionText={event.name} />
        ))}
      </SelectInput>
      <FormSubmitButton text="Update Hero Slider" />
    </Form>
  );
}

export default UpdateHeroSlider;
