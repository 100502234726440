import { useEffect, useState } from 'react';
import useAxiosInstance from '../../../../Utilities/Hooks/useAxiosInstance';
import BackButton from '../../../Partials/Elements/BackButton/BackButton';
import CRUDTable from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable';
import CRUDTableHeader from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader';
import CRUDth from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth';
import CRUDTableRow from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow';
import ShortTextCell from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell';
import EditButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton';
import Modal from '../../../Partials/Elements/Modal/Modal';
import UpdateGovSetting from './UpdateGovSetting';



function GovSetting() {
  const [govSettings, setGovSettings] = useState(null)
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchGovSetting() {
      const { data } = await axiosInstance.get(`gov/govSettings`);
      setGovSettings(data);
    }
    fetchGovSetting();
  }, [toggleFetch]);

  function handleClose() {
    setShowModal(false);
    setShowUpdateForm(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className='crud_holder'>
        <div className='body_header'>
          <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <BackButton />
            <h1 className='heading'>{`Gov Settings`}</h1>
          </div>
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th='Start Gw' />
            <CRUDth th='End Gw' />
            <CRUDth th='Max Transfers' />
            <CRUDth th='Actions' />
          </CRUDTableHeader>
          <tbody>
            {govSettings?.length > 0 && govSettings?.map((govSetting) => (
              <CRUDTableRow key={govSetting._id}>
                <ShortTextCell text={govSetting?.startGameweek} />
                <ShortTextCell text={govSetting?.endGameweek} />
                <ShortTextCell text={govSetting?.maxTransfer} />
                <td className='action_button_cell'>
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={govSetting._id}
                    setTargetID={setTargetID}
                  />
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && showUpdateForm && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={`Update Gov Settings`}
        >
          <UpdateGovSetting
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
            setShowModal={setShowModal}
            triggerFetch={triggerFetch}
            govSettings = {govSettings}
          />
        </Modal>
      )}
    </>
  );
}

export default GovSetting;
