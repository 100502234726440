import CRUDButton from '../CRUDButton';
import { IoPersonRemoveSharp } from 'react-icons/io5';
// import './ViewButton.css';
import { GiCaptainHatProfile } from 'react-icons/gi';

function CaptainButton({
  setShowModal,
  setShowMakeCaptainSection,
  setTargetID,
  targetID,
  isCaptain,
}) {
  return (
    <CRUDButton
      dataTip='Make Captain'
      dataFor='Make Captain'
      handleClick={() => {
        setShowModal(true);
        setShowMakeCaptainSection(true);
        setTargetID(targetID);
      }}
    >
      <GiCaptainHatProfile />
    </CRUDButton>
  );
}

export default CaptainButton;
