import { RiGroup2Line, RiGroupLine } from 'react-icons/ri';
import BackButton from '../../Partials/Elements/BackButton/BackButton';
import NavCard from '../../Partials/Layouts/NavCardList/NavCard/NavCard';
import NavCardList from '../../Partials/Layouts/NavCardList/NavCardList';
import AppContext from '../../../Context/AppContext';
import { useContext } from 'react';

function UsersScreen() {
  const { employee } = useContext(AppContext)
  
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '40px',
        gap: '20px',
      }}
    >
      <NavCardList numOfCards={'four'}>
        <NavCard cardName={'Managers'} navCardLink='/managers'>
          <RiGroup2Line />
        </NavCard>
        {employee?.level === "admin" &&
          <NavCard cardName={'Employees'} navCardLink='/employeesScreen'>
            <RiGroupLine />
          </NavCard>}
      </NavCardList>
    </div>
  );
}

export default UsersScreen;
