import { useState } from 'react';
import useAxiosInstance from '../../../../Utilities/Hooks/useAxiosInstance';
import Form from '../../../Partials/Layouts/Forms/Form';
import ShortTextInput from '../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput';
import NumberInput from '../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput';
import ImageInput from '../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput';
import FormSubmitButton from '../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton';

function CreateDraftGroup({ setShowCreateForm, setShowModal, round, triggerFetch }) {
  const [groupName, setGroupName] = useState('');
  const [leagueId, setLeagueId] = useState('');
  const [groupImage, setGroupImage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true)
    const itemData = new FormData()

    itemData.append('groupName', groupName)
    itemData.append('round', round)
    if (leagueId) itemData.append('leagueId', parseInt(leagueId))
    if (groupImage) itemData.append('groupImage', groupImage)

    const response = await axiosInstance.post('draft/groups', itemData);
    if (response.data) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch()
      setIsLoading(false)
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <ShortTextInput
        label='Group Name'
        value={groupName}
        placeholder='Enter Group Name'
        setState={setGroupName}
      />
      <NumberInput
        value={leagueId}
        setState={setLeagueId}
        placeholder={'Enter League Id'}
        label={'League Id'}
      />

      <ImageInput
        children={"Upload Group Image"}
        fieldId={1}
        state={groupImage}
        setState={setGroupImage}
        allowCreateImage
      />
      <FormSubmitButton text='Create Draft Group' isDisabled={isLoading} />
    </Form>
  );
}

export default CreateDraftGroup;
