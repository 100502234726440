

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAxiosInstance from '../../../../../Utilities/Hooks/useAxiosInstance';
import BackButton from '../../../../Partials/Elements/BackButton/BackButton';
import CRUDTable from '../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable';
import CRUDTableHeader from '../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader';
import CRUDth from '../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth';
import CRUDTableRow from '../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow';
import ShortTextCell from '../../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell';

function GroupUsers() {
    const { groupId } = useParams()
    const [users, setUsers] = useState([])
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        async function fetchGroupUsers() {
            const { data } = await axiosInstance.get(`draft/getUserByGroup/${groupId}`);
            setUsers(data);
        }
        fetchGroupUsers();
    }, [groupId]);

    return (
        <>
            <div className='crud_holder'>
                <div className='body_header'>
                    <div style={{display: "flex",gap:"20px",alignItems: 'center'}}>
                        <BackButton />
                        <h1 className='heading'>{`Group Users (${users?.length || 0})`}</h1>
                    </div>

                </div>
                <CRUDTable>
                    <CRUDTableHeader>
                        <CRUDth th='Group Name' />
                        <CRUDth th='League ID' />
                        <CRUDth th='Draft ID' />
                        <CRUDth th='Name' />
                        <CRUDth th='Team Name' />
                        <CRUDth th='Total Points' />

                    </CRUDTableHeader>
                    <tbody>
                        {users?.map((user) => (
                            <CRUDTableRow key={user._id}>
                                <ShortTextCell text={user?.group?.groupName} />
                                <ShortTextCell text={user?.group?.leagueId} />
                                <ShortTextCell text={user?.draftId} />
                                <ShortTextCell text={user?.playerName} />
                                <ShortTextCell text={user?.teamName} />
                                <ShortTextCell text={user?.totalPoints} />

                            </CRUDTableRow>
                        ))}
                    </tbody>
                </CRUDTable>
            </div>

        </>
    );
}

export default GroupUsers;
