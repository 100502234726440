import { useEffect, useState } from 'react';
import CreateDraftRound from './CreateDraftRound';
import UpdateDraftRound from './UpdateDraftRound';
import useAxiosInstance from '../../../../Utilities/Hooks/useAxiosInstance';
import BackButton from '../../../Partials/Elements/BackButton/BackButton';
import CreateButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton';
import CRUDTable from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable';
import CRUDTableHeader from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader';
import CRUDth from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth';
import CRUDTableRow from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow';
import ShortTextCell from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell';
import EditButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton';
import ArchiveButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ArchiveButton/ArchiveButton';
import Modal from '../../../Partials/Elements/Modal/Modal';
import { FiExternalLink } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import CRUDButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton';

function DraftRound() {
  const [draftRounds, setDraftRounds] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchDraftRounds() {
      const { data } = await axiosInstance.get('draft/rounds');
      setDraftRounds(data);
    }
    fetchDraftRounds();
  }, [toggleFetch]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowUpdateForm(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }
  const navigate = useNavigate()

  return (
    <>
      <div className='crud_holder'>
        <div className='body_header'>
          <BackButton />
          <h1 className='heading'>{`Draft Rounds (${draftRounds?.length} in total)`}</h1>
          <CreateButton
            screenTopicSingular='Draft Round'
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th='Round Name' />
            <CRUDth th='Start Gameweek' />
            <CRUDth th='End Gameweek' />
            <CRUDth th='Disqualified Number' />
            <CRUDth th='Actions' />
          </CRUDTableHeader>
          <tbody>
            {draftRounds?.map((round) => (
              <CRUDTableRow key={round._id}>
                <ShortTextCell text={round.roundName} />
                <ShortTextCell text={round.startGw} />
                <ShortTextCell text={round.endGw} />
                <ShortTextCell text={round.disqualifiedNumbers} />
                <td className='action_button_cell'>
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={round._id}
                    setTargetID={setTargetID}
                  />
                  <CRUDButton
                    dataTip='Round Group'
                    dataFor='Round Group'
                    handleClick={() => {
                      navigate(`/draftGroups/${round._id}`)
                    }}
                  >
                    <FiExternalLink />
                  </CRUDButton>

                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && 'Create Draft Round') ||
            (showUpdateForm && 'Update Draft Round')
          }
        >
          {showCreateForm && (
            <CreateDraftRound
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateDraftRound
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}
    </>
  );
}

export default DraftRound;
