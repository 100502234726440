import { useEffect, useState } from "react";
import NumberInput from "../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../Partials/Layouts/Forms/Form";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";


function UpdateLscGameweek({
    targetID,
    setShowUpdateForm,
    setShowModal,
    triggerFetch,
    allGameweks
}) {


    const [gameweek, setGameweek] = useState(null)
    const [disqualifiedNumbers, setDisqualifiedNumbers] = useState(0)
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        if (targetID) {
            const gwData = allGameweks.find(gw => gw._id === targetID)
            if (gwData) {
                setGameweek(gwData.gameWeek)
                setDisqualifiedNumbers(gwData.disqualifiedNumbers)
            }
        }
    }, [targetID]);

    async function handleSubmit(e) {

        e.preventDefault();
        const itemData = { disqualifiedNumbers }

        const response = await axiosInstance.patch("lsc/updateLscGw/" + targetID, itemData);

        if (response.data) {
            setShowUpdateForm(false);
            setShowModal(false);
            triggerFetch();
        }
    }

    return (
        <Form onSubmit={handleSubmit} >

            <h3 style={{paddingBottom: '30px'}}>Gameweek: {gameweek}</h3>

            <NumberInput
                label={`Disqualified Number`}
                value={disqualifiedNumbers}
                placeholder={`Enter Disqualified Number`}
                setState={setDisqualifiedNumbers}
            />

            <FormSubmitButton text="Update Disqualified Numbers" />
        </Form>
    );
}

export default UpdateLscGameweek;
