import { useState } from 'react';
import useAxiosInstance from '../../../../Utilities/Hooks/useAxiosInstance';
import Form from '../../../Partials/Layouts/Forms/Form';
import ShortTextInput from '../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput';
import NumberInput from '../../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput';
import FormSubmitButton from '../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton';

function CreateDraftRound({ setShowCreateForm, setShowModal, triggerFetch }) {
  const [roundName, setRoundName] = useState('');
  const [startGw, setStartGw] = useState('');
  const [endGw, setEndGw] = useState('');
  const [disqualifiedNumbers, setDisqualifiedNumbers] = useState(0)
  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();
    const data = { roundName, startGw: parseInt(startGw), endGw: parseInt(endGw), disqualifiedNumbers };
    await axiosInstance.post('draft/rounds', data);
    setShowCreateForm(false);
    setShowModal(false);
    triggerFetch();
  }

  return (
    <Form onSubmit={handleSubmit}>
      <ShortTextInput
        label='Round Name'
        value={roundName}
        placeholder='Enter Round Name'
        setState={setRoundName}
      />
      <NumberInput
        label='Start Game Week'
        value={startGw}
        placeholder='Enter Start Game Week'
        setState={setStartGw}
      />
      <NumberInput
        label='End Game Week'
        value={endGw}
        placeholder='Enter End Game Week'
        setState={setEndGw}
      />
      <NumberInput
        label='Disqualified Numbers'
        value={disqualifiedNumbers}
        placeholder='Enter Disqualified Numbers'
        setState={setDisqualifiedNumbers}
      />
      <FormSubmitButton text='Create Draft Round' />
    </Form>
  );
}

export default CreateDraftRound;
