import { useContext, useEffect, useState } from 'react';

import Modal from '../../../Partials/Elements/Modal/Modal';
import ViewButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton';
import CRUDTable from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable';
import ShortTextCell from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell';
import CRUDTableHeader from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader';
import CRUDth from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth';
import CRUDTableRow from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow';
import { ALL_MANAGER_API } from './../../../../Utilities/APIs/APIs';
import useAxiosInstance from '../../../../Utilities/Hooks/useAxiosInstance';

import AppContext from '../../../../Context/AppContext';
import BackButton from '../../../Partials/Elements/BackButton/BackButton';
import CaptainButton from '../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/captainButton/captainButton.js';
import MakeCaptain from './MakeCaptain.js';
import ViewManager from './ViewManager.js';

function Managers() {

  const [customers, setCustomers] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showMakeCaptainSection, setShowMakeCaptainSection] = useState(false);
  const { setIsLoading, toggle } = useContext(AppContext);
  const axiosInstance = useAxiosInstance();


  useEffect(() => {
    async function fetchAndSetCustomers() {
      const { data } = await axiosInstance.get(ALL_MANAGER_API + 'getAllManagers');
      setCustomers(data);
    }
    fetchAndSetCustomers();
  }, [axiosInstance, setIsLoading, toggle]);

  function handleClose() {
    setShowModal(false);
    setShowViewSection(false);
    setShowMakeCaptainSection(false);
  }

  return (
    <>
      <div className='crud_holder'>
        <div className='body_header'>
          <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <BackButton />
            <h1 className='heading'>{`Managers (${customers?.length > 0 ? customers.length : 0
              } in total)`}</h1>
          </div>
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th='Manager Name' />
            <CRUDth th='University' />
            <CRUDth th='Email' />
            <CRUDth th='Actions' />
          </CRUDTableHeader>
          <tbody>
            {customers?.map((customer) => (
              <CRUDTableRow key={customer?._id}>
                <ShortTextCell text={customer?.name} />
                <ShortTextCell text={customer?.university?.name} />
                <ShortTextCell text={customer?.email} />
                <td className='action_button_cell'>
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={customer?._id}
                    setTargetID={setTargetID}
                  />

                  <CaptainButton
                    setShowModal={setShowModal}
                    setShowMakeCaptainSection={setShowMakeCaptainSection}
                    targetID={customer._id}
                    setTargetID={setTargetID}
                    isCaptain={customer?.isCaptain}
                  />
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && showViewSection && (
        <Modal
          handleClose={handleClose}
          modalHeading={showViewSection && `View Manager`}
        >
          {showViewSection && <ViewManager targetID={targetID} />}
        </Modal>
      )}
      {showMakeCaptainSection && (
        <Modal
          handleClose={handleClose}
          modalHeading={showMakeCaptainSection && `Make Captain`}
        >
          {showMakeCaptainSection && (
            <MakeCaptain
              targetID={targetID}
              setShowModal={setShowModal}
              setShowMakeCaptainSection={setShowMakeCaptainSection}
            />
          )}
        </Modal>
      )}
    </>
  );
}

export default Managers;
