import {  useEffect, useState } from "react";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";
import BackButton from "../../Partials/Elements/BackButton/BackButton";
import CRUDTable from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import EditButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import Modal from "../../Partials/Elements/Modal/Modal";
import UpdateRoundGameweek from "./UpdateRoundGameweek";

function BorRoundGameweek() {
    const [roundGameweeks, setRoundGameweeks] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [targetID, setTargetID] = useState(null);
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        async function fetchRoundGameweeks() {
            const { data } = await axiosInstance.get("/borRoundGw");
            setRoundGameweeks(data);
        }
        fetchRoundGameweeks();
    }, []);

    function handleClose() {
        setShowModal(false);
        setShowUpdateForm(false);
    }

    return (
        <>
            <div className="crud_holder">
                <div className="body_header">
                    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                        <BackButton />
                        <h1 className="heading">{`Rounds (${roundGameweeks?.length} in total)`}</h1>
                    </div>
                </div>
                <CRUDTable>
                    <CRUDTableHeader>
                        <CRUDth th="Round Name" />
                        <CRUDth th="Number of Gameweeks" />
                        <CRUDth th="Actions" />
                    </CRUDTableHeader>
                    <tbody>
                        {roundGameweeks?.length > 0 &&
                            roundGameweeks?.map((round) => (
                                <CRUDTableRow key={round._id}>
                                    <ShortTextCell text={round?.roundName} />
                                    <ShortTextCell text={round?.numberOfGameweeks} />

                                    <td className="action_button_cell">
                                        <EditButton
                                            setShowModal={setShowModal}
                                            setShowUpdateForm={setShowUpdateForm}
                                            targetID={round._id}
                                            setTargetID={setTargetID}
                                        />
                                    </td>
                                </CRUDTableRow>
                            ))}
                    </tbody>
                </CRUDTable>
            </div>
            {showModal && (
                <Modal
                    medium={true}
                    handleClose={handleClose}
                    modalHeading={
                        (showUpdateForm && `Update Round Gameweek`) || `Details`
                    }
                >
                    {showUpdateForm && (
                        <UpdateRoundGameweek
                            targetID={targetID}
                            setShowUpdateForm={setShowUpdateForm}
                            setShowModal={setShowModal}
                        />
                    )}
                </Modal>
            )}
        </>
    );
}

export default BorRoundGameweek;
