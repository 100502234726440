


import NavCardList from '../../../Partials/Layouts/NavCardList/NavCardList'
import NavCard from '../../../Partials/Layouts/NavCardList/NavCard/NavCard'
import { RiGroupLine } from 'react-icons/ri'
import { FiSettings } from 'react-icons/fi';


function GovDashboard() {

  return (
    <NavCardList numOfCards={'four'}>
      <NavCard cardName={'Gov Users'} navCardLink='/govUsers' ><RiGroupLine /></NavCard>
      <NavCard cardName={'Gov Settings'} navCardLink='/govSettings' ><FiSettings /></NavCard>
    </NavCardList>
  )
}

export default GovDashboard