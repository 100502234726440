import { MANAGER_API } from '../../../../Utilities/APIs/APIs'
import useAxiosInstance from '../../../../Utilities/Hooks/useAxiosInstance'
import '../../../Partials/Layouts/DeleteItem/DeleteItem.css'

function RemoveCaptain({ targetID, setShowRemoveCaptainSection, setShowModal, triggerFetch }) {

    const axiosInstance = useAxiosInstance()

    async function handleRemove(id) {

        const res = await axiosInstance.patch(MANAGER_API + id, { isCaptain: false })

        if (res.data) {
            setShowModal(false)
            setShowRemoveCaptainSection(false)
            triggerFetch()
        }
    }

    return (
        <div className="delete_item">
            <div className="delete_content">
                <h1>Are you sure?</h1>
                <p>Please confirm you want to remove this manager from captain.</p>
                <div className="btn_container">

                    <button
                        onClick={() => handleRemove(targetID)}
                        className='delete'
                    >Remove
                    </button>

                    <button className='cancel'
                        onClick={() => {
                            setShowModal(false);
                        }}
                    >Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default RemoveCaptain 