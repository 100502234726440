

function FilterSelect({ filter, setFilter, filters }) {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>

            <select className="filter_select" value={filter} onChange={(e) => setFilter(e.target.value)} >

                {
                    filters?.length > 0 ?

                        <>
                            {filters?.map(filter => (
                                <option value={filter.value}> {filter?.label}</option>
                            ))}

                        </>
                        :
                        <>
                            <option value={"active"}>Active</option>
                            <option value={"archive"}>Archive</option>
                        </>
                }
            </select>
        </div>

    )
}

export default FilterSelect