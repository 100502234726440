import { RiAdvertisementLine, RiGroup2Line, RiGroupLine } from 'react-icons/ri';
import NavCardList from '../../Partials/Layouts/NavCardList/NavCardList';
import NavCard from '../../Partials/Layouts/NavCardList/NavCard/NavCard';

function AppSettingsScreens() {
  return (
    <div>
      <NavCardList numOfCards={'four'}></NavCardList>
    </div>
  );
}

export default AppSettingsScreens;
