import './CRUDTable.css'

function CRUDTable({children,fullView = true}) {
  return (
    <div className={`${fullView ? "full_view_data_table_wrapper" : "data_table_wrapper"}`}>
        <table 
            id="dashboardTable" 
            className="table dataTable table-borderless align-middle text-center dashboard_table"
        >
            {children}
        </table>
    </div>  )
}

export default CRUDTable